import React from 'react';
import '../styles/QFCommunityStandards.css';

const CommunityStandards = () => {
  return (
    <div className="community-standards-container">
      <h1>Community Standards</h1>
      
      <h2>General</h2>
      <p>
        QuickFeed AI Pvt. Ltd. is committed to providing a reliable, factual, and respectful platform for users to access concise news from a variety of credible sources. To maintain a safe and high-quality experience on the QuickFeed app, we have established a set of Community Standards (“Standards”) that outline the expected behavior for users and publishers on our platform. These Standards apply to all users and content on QuickFeed and are regularly updated to address new risks, trends, and behaviors.
      </p>
      <p>
        Our goal is to ensure that content shared on QuickFeed is trustworthy, relevant, and presented in a manner that fosters healthy engagement. As a news aggregator, we source content from various publishers and want to ensure that all contributions adhere to these guidelines to protect the integrity of the platform.
      </p>

      <h2>Core Principles</h2>
      <ul>
        <li><strong>Credibility of Information:</strong> Only accurate, verified, and credible news from reliable sources should be shared. Misinformation or content that manipulates facts is not allowed.</li>
        <li><strong>Content Suitability:</strong> News content should be appropriate for a broad audience. Any potentially sensitive content must be marked clearly to prevent unintended exposure.</li>
        <li><strong>Respectful Engagement:</strong> Users should engage in a civil and respectful manner, promoting healthy discussions around news and current events.</li>
        <li><strong>Community Empowerment:</strong> We encourage the community to report inappropriate or misleading content, and we provide tools to help maintain the safety and integrity of the platform.</li>
      </ul>

      <h2>Prohibited Content</h2>
      <ul>
        <li><strong>Misinformation and Fake News:</strong> Any content that spreads false, manipulated, or misleading information, including fabricated news, rumors, or conspiracy theories.</li>
        <li><strong>Hate Speech and Discrimination:</strong> Content that attacks or discriminates against individuals or groups based on race, ethnicity, nationality, religion, gender, sexual orientation, disability, or other protected characteristics.</li>
        <li><strong>Violence and Harassment:</strong> Content that incites violence, promotes harm, or involves threats, bullying, or harassment of any kind.</li>
        <li><strong>Graphic and Disturbing Content:</strong> Content that depicts graphic violence, self-harm, or other distressing visuals, even if they are newsworthy, should be clearly labeled and handled sensitively.</li>
        <li><strong>Obscene and Sexual Content:</strong> Content containing sexually explicit material, nudity, or provocative imagery is not suitable for the platform.</li>
        <li><strong>Promotion of Dangerous Acts:</strong> Content that encourages or glorifies dangerous activities, challenges, or behavior that could result in physical or mental harm.</li>
        <li><strong>Terrorism and Extremism:</strong> Content that supports or glorifies terrorism, extremist ideologies, or violent organizations is strictly prohibited.</li>
        <li><strong>Illegal and Fraudulent Activities:</strong> Content promoting illegal activities such as fraud, scams, or the trade of prohibited goods or services.</li>
        <li><strong>Misinformation During Crises:</strong> Content that spreads panic or misinformation during crises (e.g., health emergencies, natural disasters) is not permitted.</li>
        <li><strong>Privacy Violations:</strong> Content that reveals private information or encourages doxxing of individuals without consent.</li>
      </ul>

      <h2>Content Guidelines for Publishers</h2>
      <p>
        As a news aggregator, QuickFeed collaborates with multiple publishers to present reliable and varied perspectives on current events. Publishers are expected to uphold the following guidelines:
      </p>
      <ul>
        <li><strong>Adherence to Journalistic Standards:</strong> All content must be factual, well-researched, and sourced from reputable outlets.</li>
        <li><strong>No Clickbait or Sensationalism:</strong> Headlines and snippets should accurately reflect the content without using misleading or sensationalized language to drive traffic.</li>
        <li><strong>Neutral and Unbiased Reporting:</strong> Publishers should present news in an objective manner without pushing personal or political biases.</li>
        <li><strong>Transparency of Sources:</strong> All sources must be cited appropriately, and any external links should lead to verified information.</li>
      </ul>

      <h2>Community Interaction Guidelines</h2>
      <p>
        QuickFeed encourages users to share their opinions and engage with news content in a respectful manner. The following guidelines apply to all user interactions:
      </p>
      <ul>
        <li><strong>Respectful Discourse:</strong> Comments and feedback should be constructive, avoiding any form of abuse, hate speech, or offensive language.</li>
        <li><strong>No Personal Attacks:</strong> Users should refrain from attacking other individuals or groups based on personal opinions, attributes, or beliefs.</li>
        <li><strong>Report Violations:</strong> Users can report any content or interactions that violate these Standards, and we will take appropriate actions.</li>
      </ul>

      <h2>Enforcement and Action</h2>
      <p>
        QuickFeed takes violations of these Standards seriously and employs a range of measures to address inappropriate behavior or content:
      </p>
      <ul>
        <li><strong>Content Removal:</strong> Content that violates these Standards will be removed promptly.</li>
        <li><strong>Account Restrictions:</strong> Users or publishers who repeatedly violate our Standards may face temporary or permanent account restrictions.</li>
        <li><strong>Publisher Penalties:</strong> Publishers found to consistently post misleading or harmful content may have their content distribution rights revoked.</li>
      </ul>
      
      <p>We reserve the right to update these Standards at any time to ensure the safety, credibility, and reliability of our platform.</p>
    </div>
  );
};

export default CommunityStandards;
