import React, { useState } from 'react';
import '../styles/QFFaq.css';

const faqs = [
  {
    question: "What is QuickFeed?",
    answer: "QuickFeed is your personalized news app that delivers concise, AI-generated news summaries in a sleek, card-like layout. It keeps you updated on trending topics and daily headlines without the clutter."
  },
  {
    question: "How does QuickFeed work?",
    answer: "QuickFeed uses advanced AI to search the web for the latest news stories, summarizes them into bite-sized pieces, and organizes them into an intuitive interface. You can customize your feed by following your favorite categories, publications, and sources, and QuickFeed keeps everything streamlined for a hassle-free reading experience."
  },
  {
    question: "What are the top features of QuickFeed?",
    answer: [
      "Superior UI, focused on user ease and experience.",
      "Personalized news tailored to user preferences.",
      "AI-powered summaries for concise and relevant content.",
      "Diverse content formats and real-time updates.",
      "Reader mode for a distraction-free reading experience.",
      "Unique features like:",
      [
        "Quick Digest for daily top headlines.",
        "Quick Buzz for trending news highlights.",
        "Quick Scoop for breaking news and exclusive stories.",
        "Quick Wrap for weekly news recaps."
      ]
    ]
  },
  {
    question: "Is QuickFeed available on both iOS and Android?",
    answer: "Yes, QuickFeed is available for download on both the Apple App Store and Google Play Store."
  },
  {
    question: "How do I use the QuickFeed app?",
    answer: [
      "Home Screen: When you open the app, the home screen displays trending articles in Mini cards, each featuring a photo and headline. Swipe left to browse these trending articles.",
      "Category Articles: Below the Mini cards, you'll find Micro articles organized by categories. By default, these articles are mixed together. Click on any category (e.g., Entertainment) to filter specific articles, which will be displayed horizontally.",
      "Reading Summaries: Click on any Micro article to access its summary page (Macro articles), where you’ll see a photo, title, and AI-generated summary.",
      "Full Articles: To read the full article, click the hyperlink (title) on the Macro article page. You can choose to read in normal mode or distraction-free reader mode.",
      "Enjoy exploring the news tailored to your interests!"
    ]
  },
  {
    question: "How do I personalize my news feed on QuickFeed?",
    answer: "You can personalize your feed by selecting your favorite categories, sources, and publications in the \"Personalize\" section of the app. Simply follow the categories that interest you the most."
  },
  {
    question: "How do I use the QuickDigest feature?",
    answer: "QuickDigest compiles the top stories of the day into a single summary. You can access it in the main page of the app under the \"QuickDigest\" section for a daily news recap."
  },
  {
    question: "Can I use QuickFeed offline?",
    answer: "Currently, QuickFeed requires an internet connection to pull the latest news and generate summaries. However, stories you've viewed may be accessible offline temporarily."
  },
  {
    question: "Can I read news without distractions?",
    answer: "Yes! QuickFeed offers a Reader Mode for a clean, distraction-free reading experience. To use it, open the article and click the reading mode icon at the top right corner, next to the browser icon (which opens the news in Safari). This will display the article in a simple, easy-to-read format."
  },
  {
    question: "Can I follow specific topics or categories to get more tailored news?",
    answer: "Absolutely! With QuickFeed, you can follow specific topics or categories that interest you. Whether it's technology, sports, entertainment, or business, QuickFeed allows you to personalize your news feed. Just head to the Explore section, choose the categories you want to follow, and the app will start curating news tailored to your preferences."
  },
  {
    question: "How can I enable Dark Mode in the QuickFeed app?",
    answer: "You can easily enable Dark Mode by navigating to the app's Settings. Look for the \"Dark Mode\" section, where you'll find the option to toggle on and off. Select \"Dark Mode\" to enjoy a more comfortable reading experience, especially in low-light environments."
  },
  {
    question: "What is Quick Buzz?",
    answer: "Quick Buzz keeps you informed with real-time updates on trending news highlights, so you’re always in the know about current hot topics."
  },
  {
    question: "What is Quick Scoop?",
    answer: "Quick Scoop brings you breaking news and exclusive stories, ensuring you're up-to-date with urgent events and in-depth insights."
  },
  {
    question: "What is Quick Wrap?",
    answer: "Quick Wrap provides a weekly recap of all the major news stories, so you can easily catch up on the week’s top headlines."
  },
  {
    question: "How can I sign up or log in to the QuickFeed app?",
    answer: [
      "You can sign up or log in to the QuickFeed app easily from the Settings menu. Here are the available options:",
      [
        "Apple Login: Tap the \"Sign up/Login with Apple\" button to use your Apple ID for a quick and secure sign-in.",
        "Google Login: Select \"Sign up/Login with Google\" to sign in using your Google account credentials.",
        "Email Sign-Up: If you prefer, you can also sign up using your email address. Simply enter your email in the designated field and follow the prompts to complete the sign-up process.",
      ],
      "After signing up, you can log in using any of these methods from the Settings menu at any time."
    ]
  },
  {
    question: "What are the advantages of signing in to the QuickFeed app?",
    answer: [
      "Signing in to the QuickFeed app offers several benefits that enhance your overall experience:",
      [
        "Bookmark Articles: When you sign in, you can easily bookmark and save articles for later reading. This feature allows you to curate a personalized list of your favorite content, making it convenient to revisit important stories at any time.",
        "Customization Options: Signing in enables more customization features tailored to your preferences. You can adjust your reading experience, including selecting topics of interest, managing notification settings, and personalizing your feed to highlight the news that matters most to you.",
        "Sync Across Devices: Your signed-in account ensures that your bookmarks, preferences, and reading history sync seamlessly across all your devices. Whether you’re on your phone or tablet, your content is always accessible.",
        "Enhanced Features: As a signed-in user, you gain access to exclusive features, updates, and improvements that enhance your app experience."
      ],
      "By signing in, you unlock these features and create a more tailored news consumption experience!"
    ]
  },
];

const QFFaq = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const renderAnswer = (answer) => {
    if (Array.isArray(answer)) {
      return (
        <ul>
          {answer.map((item, index) => (
            <li key={index}>
              {Array.isArray(item) ? (
                <ul className="nested-list">
                  {item.map((nestedItem, nestedIndex) => (
                    <li key={nestedIndex}>{nestedItem}</li>
                  ))}
                </ul>
              ) : (
                item
              )}
            </li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };

  return (
    <div className="faq-container">
      <h1>FAQs QuickFeed</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <h2>{faq.question}</h2>
            <span>{openIndex === index ? '-' : '+'}</span>
          </div>
          {openIndex === index && (
            <div className="faq-answer">
              {renderAnswer(faq.answer)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default QFFaq;
