// Attributes.js
import React, { useState } from 'react';
import '../styles/QFAttributes.css';
import attributesData from '../data/attributes.json';

const PAGE_SIZE = 10;

const QFAttributes = () => {
  const [currentPage, setCurrentPage] = useState(1);

  // Paginate data
  const totalPages = Math.ceil(attributesData.length / PAGE_SIZE);
  const displayedAttributes = attributesData.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="attributes-container">
      <h1>Attributes</h1>
      <p>Here are the various external resources used in our application:</p>

      <div className="attributes-list">
        {displayedAttributes.map((attribute) => (
          <div key={attribute.id} className="attribute-card">
            {attribute.attribute_url && (
              <p>
                <strong>Link:</strong> <a href={attribute.attribute_url} target="_blank" rel="noopener noreferrer">
                  {attribute.attribute_url}
                </a>
              </p>
            )}
            <div className="license-info">
              <strong>License:</strong>
              <p dangerouslySetInnerHTML={{ __html: attribute.atrribute_licence }} />
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default QFAttributes;
