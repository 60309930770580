import React from 'react';
import '../styles/QFPrivacyPolicy.css';

const QFPrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>

      <h2>1. General</h2>
      <p><strong>1.1</strong> QuickFeed AI Pvt. Ltd. (“QuickFeed”, “We”, “Our”, “Us”) is committed to protecting the personal information of users (“You”, “Your”, “User”) provided to QuickFeed. Your use of the QuickFeed app implies Your consent to collect, store, and handle Your personal information as described in this Privacy Policy.</p>
      <p><strong>1.2</strong> This Privacy Policy applies to all users accessing the app. Please read and understand the Privacy Policy before submitting any personal information.</p>
      <p><strong>1.3</strong> We value the privacy of our users and strive to safeguard it while delivering a personalized and valuable experience.</p>
      <p><strong>1.4</strong> Access to the app’s content is conditional upon Your acceptance of this Privacy Policy, which complements the Terms of Use (“Terms”). Any undefined terms in this Privacy Policy will have the same meaning as provided in the Terms.</p>

      <h2>2. Information Collected</h2>
      <h3>2.1 Traffic Data Collected</h3>
      <p>We may collect the following categories of information automatically when You use the app:</p>
      <ul>
        <li>(i) IP addresses;</li>
        <li>(ii) Domain servers; and</li>
        <li>(iii) Other information regarding Your device and its interaction with the app.</li>
      </ul>

      <h3>2.2 Personal Information Collected</h3>
      <p>To provide services through the app, We may require the following types of personal information:</p>
      <ul>
        <li>(i) Contact data (email address, phone number, etc.);</li>
        <li>(ii) Device data;</li>
        <li>(iii) Demographic data (time zone, location, etc.).</li>
      </ul>
      <p>We may also collect additional information if You communicate with Us, such as through emails or letters.</p>

      <h3>2.3 Data Storage</h3>
      <p>Your personal information may be stored on internal servers, which may be located outside India, and is retained for up to 180 days after account deletion or as required by law.</p>

      <h3>2.4 Third-Party Links</h3>
      <p>The app may contain links to third-party websites or applications. These are not controlled by QuickFeed, and we recommend reviewing their privacy policies separately.</p>

      <h2>3. Usage of Personal Information</h2>
      <p>We use Your information to:</p>
      <ul>
        <li>Ensure the app is delivered effectively,</li>
        <li>Fulfill Our obligations to You,</li>
        <li>Enhance user experience,</li>
        <li>Communicate with You through various means (calls, texts, or emails), and</li>
        <li>For purposes such as marketing profiles, strategic development, data analytics, and managing relationships with advertisers.</li>
      </ul>

      <h2>4. Disclosure of Personal Information</h2>
      <p><strong>4.1</strong> We do not share Your personal information with third parties, except with affiliates, service providers, or trusted partners under a lawful contract.</p>
      <p><strong>4.2</strong> No user information is rented or sold to any third party.</p>
      <p><strong>4.3</strong> In case of mergers or asset sales, Your personal information may be transferred to relevant third parties with the same rights of use.</p>
      <p><strong>4.4</strong> Disclosure may also occur if required for legal compliance or to protect QuickFeed’s rights and safety.</p>

      <h2>5. Security</h2>
      <p><strong>5.1</strong> We take commercially reasonable measures to protect Your personal information against unauthorized access or misuse.</p>
      <p><strong>5.2</strong> While We strive to secure Your data, there is no such thing as complete security, and We cannot guarantee absolute protection.</p>
      <p><strong>5.3</strong> Users are advised to safeguard their passwords and notify Us immediately if an account is compromised.</p>

      <h2>6. Updates to Privacy Policy</h2>
      <p>We may update this policy periodically. Any changes will be posted on this page, and continued use of the app signifies acceptance of the updated policy.</p>

      <h2>7. Your Rights</h2>
      <p><strong>7.1</strong> You can review and correct errors in Your personal information by contacting us.</p>
      <p><strong>7.2</strong> You may also request data deletion or cessation of its use, but this may limit Your access to the app’s services.</p>

      <h2>8. Contact Us</h2>
      <p>For any queries or concerns regarding our Privacy Policy, you may reach out to Mr. Mahendar Mali at <a href="mailto:quickfeed.ai@gmail.com">quickfeed.ai@gmail.com</a>. We will make every effort to address your concerns promptly.</p>

      <p>Last Updated: October 2024</p>
    </div>
  );
};

export default QFPrivacyPolicy;
