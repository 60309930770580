import React from 'react';
import '../styles/QFTermsAndConditionsOfUse.css';

const QFTermsAndConditionsOfUse = () => {
  return (
    <div className="terms-conditions-container">
      <h1>TERMS AND CONDITIONS OF USE</h1>

      <p>
        These terms and conditions of use (“Terms”), along with the privacy policy (“Privacy Policy”), form a legally binding agreement (“Agreement”) between You and Us (“QuickFeed AI Pvt. Ltd.”, “QuickFeed”, “Our”, “We”). Hence, We encourage You to spend time reading these Terms and Privacy Policy and contact us at <a href="mailto:grievances@quickfeed.com">grievances@quickfeed.com</a> if You have any questions regarding the same. We will strive to address Your queries at the earliest.
      </p>

      <h2>A. DEFINITIONS AND INTERPRETATION</h2>
      <ul>
        <li>(a) “App” means the QuickFeed mobile platform downloadable from Google Play/App Store, and owned by Us, including any updates thereof.</li>
        <li>(b) “App Store” means the service provided by Apple Inc. and/or its affiliates, through which You may download the App.</li>
        <li>(c) “App Store Terms and Conditions” means the terms and conditions that apply to the use of the App Store platform and are available at <a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html" target="_blank" rel="noopener noreferrer">App Store Terms</a>.</li>
        <li>(d) “Google Play” means the service provided by Google Ireland Limited, a third party, and/or its affiliates, through which You may download the App.</li>
        <li>(e) “Google Play Terms of Service” means the terms of service that apply to the use of Google Play and are available at <a href="https://play.google.com/about/play-terms.html" target="_blank" rel="noopener noreferrer">Google Play Terms</a>.</li>
        <li>(f) “Sponsored Content” means content distinct from other regular editorial content displayed on the App, in the form of audio, video, text, and/or image media, which supports a third-party Person’s brand message or views. It is readily identifiable upfront through distinct and distinguishable font, color, display schemes, and/or usage of disclaiming words.</li>
        <li>(g) “User”, “You”, or “Your” refers to a person who has accepted this Agreement in order to download and use the App.</li>
      </ul>

      <h2>B. YOUR APPROVAL</h2>
      <p>You approve and accept the Agreement by:</p>
      <ul>
        <li>(a) Downloading and/or installing the App on Your device; or</li>
        <li>(b) Accessing or using the App or any of the content available within the App from any device.</li>
      </ul>
      <p>
        You can accept the Agreement only if You are of legal age in Your jurisdiction and capable of forming a binding contract, or if You represent a legal entity with the authority to enter this Agreement.
      </p>

      <h2>C. PROVISION OF THE APP</h2>
      <p>
        The App provides an in-app browsing experience through an embedded browser that aggregates and summarizes third-party content. QuickFeed does not host or transmit third-party content, unless licensed, and links You to original sources. QuickFeed is not responsible for third-party content accuracy or data breaches from external sites.
      </p>

      <h2>D. YOUR AGREEMENT WITH QUICKFEED</h2>
      <p>
        Violating this Agreement may result in legal liability. You are responsible for Your actions while using the App. If any provision of this Agreement is found unenforceable, the rest remains valid. Our failure to act on a breach does not waive our right to enforce the Agreement.
      </p>

      <h2>E. USING THE APP</h2>
      <p>
        You will download and install the App from Google Play/App Store and keep it updated. QuickFeed grants You a limited, non-transferable license to use the App and access content, with restrictions on redistribution, modification, or commercial use.
      </p>

      <h2>F. RESTRICTIONS ON YOUR USE</h2>
      <p>
        You agree not to upload, share, or use content that is unlawful, harmful, or violates the rights of others, including intellectual property rights, or content that may threaten national security, public order, or privacy.
      </p>

      <h2>G. REPORTING VIOLATIONS AND CONTENT TAKEDOWN</h2>
      <p>
        Report violations by emailing the Grievance Officer, Mr. Mahendar Mali, at <a href="mailto:quickfeed.ai@gmail.com">quickfeed.ai@gmail.com</a> with necessary details. Immediate action will be taken for certain violations, such as content that violates privacy.
      </p>

      <h2>H. TERMINATION</h2>
      <p>
        QuickFeed may terminate user access for legal violations or other reasons. Users can terminate their agreement by discontinuing use, but certain obligations may still apply.
      </p>

      <h2>I. INTELLECTUAL PROPERTY</h2>
      <p>
        Users must comply with intellectual property laws. QuickFeed owns rights to summarized content, and all trademarks and copyrights on the App belong to QuickFeed or respective owners.
      </p>

      <h2>J. PRIVACY</h2>
      <p>
        QuickFeed’s Privacy Policy governs how personal data is handled. Users are responsible for securing their passwords and notifying authorities if unauthorized access is detected.
      </p>

      <h2>K. LIMITATION OF LIABILITY AND INDEMNIFICATION</h2>
      <p>
        QuickFeed is not liable for any damages resulting from the use of the App. Users agree to indemnify QuickFeed against claims arising from their use of the App or violations of law.
      </p>

      <h2>L. NO WARRANTIES</h2>
      <p>
        QuickFeed disclaims all warranties regarding the availability, functionality, or accuracy of the App. QuickFeed is not responsible for third-party services or unauthorized data interception.
      </p>

      <h2>M. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
      <p>
        The Agreement is governed by Singapore law. Disputes will be resolved through arbitration under Singapore International Arbitration Centre (SIAC) rules.
      </p>

      <h2>N. NOTICES</h2>
      <p>
        Notices may be posted on the App or sent to your registered email address. Continued use of the App constitutes acceptance of the notices.
      </p>

      <h2>O. DISCLAIMER</h2>
      <p>
        QuickFeed is not liable for any content errors or data theft resulting from use of the App. The App is provided “as is” without warranties.
      </p>

      <h2>P. GRIEVANCE OFFICER</h2>
      <p>
        For concerns, queries, or grievances, contact Mr. Mahendar Mali at <a href="mailto:quickfeed.ai@gmail.com">quickfeed.ai@gmail.com</a> with relevant details.
      </p>

      <p>Last updated in: October 2024</p>
    </div>
  );
};

export default QFTermsAndConditionsOfUse;
